<i18n locale="th" lang="yaml" >
page.title : "รายงานการ Claim"
page.description : "สรุปรายงานการขอ Claim ของทางศูนย์บริการ"

claim.search.keyword : "ค้นหา"
claim.search.keyword.placeholder : "เลขใบงาน หรือ เลขเคลม"
claim.search.company : "ศูนย์บริการ"
claim.search.company.placeholder : "@:common.search.view_all"
claim.search.claim_status_mode : "สถานะการเคลม"
claim.search.claim_status_mode.placeholder : "@:common.search.view_all"
claim.search.payment_request : "สถานะการเบิก"
claim.search.payment_request.placeholder : "@:common.search.view_all"

claim.search.submit.label : "เรียกดู"

enum.claim_status_mode.prepare : "เตรียมยื่น"
enum.claim_status_mode.wait-decision : "รออนุมัติ"
enum.claim_status_mode.decision : "ตอบกลับแล้ว"

enum.claim_payment_request.none : "ยังไม่เบิก"
enum.claim_payment_request.request : "ยื่นเบิกแล้ว"
enum.claim_payment_request.complete : "ได้รับเงินแล้ว"

claim.table.service_no : "ใบงาน"
claim.table.drone : "Drone"
claim.table.drone.display : "{serialNo}"
claim.table.company : "ศูนย์บริการ"
claim.table.service : "ข้อมูลใบงาน"
claim.table.service.open_date : "วันรับเรื่อง"
claim.table.service.status : "สถานะ"
claim.table.service.payment_status : "รับเงินจากลูกค้า"
claim.table.claim : "ข้อมูลการเคลม"
claim.table.claim.status : "สถานะ"
claim.table.claim.requested_datetime : "วันที่ยื่น"
claim.table.claim.decision_datetime : "วันที่ตอบ"
claim.table.claim_payment : "ข้อมูลการเบิก"
claim.table.claim_payment.total_claim_fee : "ค่าเคลม"
claim.table.claim_payment.payment_request : "สถานะการเบิก"
claim.table.claim_payment.payment_requested_date : "วันที่เบิก"
claim.table.claim_payment.payment_completed_date : "วันที่รับเงิน"
</i18n>
<template>
	<div class="page page-padding">
		<my-page-header :title="$t('page.title')" :description="$t('page.description')" />
		<div class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item :label="$t('claim.search.keyword')">
					<a-input v-model="filter.keyword"
						style="width : 200px;"
						:placeholder="$t('claim.search.keyword.placeholder')"
						allow-clear >
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('claim.search.company')">
					<CompanyMultipleSelect
						allow-clear
						:placeholder="$t('claim.search.company.placeholder')"
						@change="handleCompanyChange"/>
				</a-form-item>

				<a-form-item :label="$t('claim.search.claim_status_mode')">
					<a-select
						class="claim-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('claim.search.claim_status_mode.placeholder')"
						:options="claimStatusModeOptions"
						allow-clear
						:default-value="filter.claimStatusMode"
						@change="handleClaimStatusChange"/>
				</a-form-item>

				<a-form-item :label="$t('claim.search.payment_request')">
					<a-select
						class="claim-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('claim.search.payment_request.placeholder')"
						:options="paymentRequestOptions"
						allow-clear
						@change="handlePaymentRequestChange"/>
				</a-form-item>
			</a-form>
		</div>

		<a-card
			:bordered="false">
			<a-table
				:loading="loading"
				:columns="claimColumns"
				:data-source="serviceDataList"
				bordered
				size="small"
				:pagination="pagination"
				:row-key="record => record.id"
				class="mytable mytable-scroll claim-table"
				@change="handleTableChange">
				<span slot="serviceNoRender" slot-scope="text, record">
					<my-router-link name="service/claim" :param-value="record.id">
						{{record.serviceNo}}
					</my-router-link>
				</span>
				<span slot="droneRender" slot-scope="droneServiceDroneInstance">
					<my-router-link v-if="droneServiceDroneInstance" name="drone/view" :param-value="droneServiceDroneInstance.serialNo" param-name="serial_no">
						{{$t('claim.table.drone.display',{ name: droneServiceDroneInstance.name,serialNo: droneServiceDroneInstance.serialNo })}}
					</my-router-link>
				</span>
				<span slot="companyRender" slot-scope="serviceCompanyId">
					{{serviceCompanyId.name}}
				</span>
				<span slot="serviceStatusRender" slot-scope="text, record">
					<StatusDot
						:status="$tenum('service.status',record.serviceStatus)"
						:description="$tenum('service.status',record.serviceStatus + '.description')"
						:reason="record.serviceStatusReason"
						:icon-mode="lookupStatusDot('service',record.serviceStatus)"/>
				</span>
				<span slot="paymentStatusRender" slot-scope="text, record">
					<StatusDot
						:status="$tenum('service.payment.status',record.servicePaymentStatus)"
						:icon-mode="lookupStatusDot('payment',record.servicePaymentStatus)"/>
				</span>

				<span slot="claimStatusRender" slot-scope="text, record">
					<StatusDot
						:status="$tenum('claim.status',record.serviceClaimStatus)"
						:description="$tenum('claim.status',record.serviceClaimStatus + '.description')"
						:icon-mode="lookupStatusDot('claim',record.serviceClaimStatus)"/>
				</span>
				<span slot="claimPaymentStatusRender" slot-scope="text, record">
					<StatusDot
						:status="$tenum('claim.payment_request',record.serviceClaimPaymentRequest)"
						:icon-mode="lookupStatusDot('payment_request',record.serviceClaimPaymentRequest)"/>
				</span>

				<span slot="dateRender" slot-scope="dateString">
					{{dateString | date}}
				</span>
				<span slot="priceRender" slot-scope="price">
					{{price | price(true)}}
				</span>
			</a-table>
		</a-card>
	</div>
</template>

<script>
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import PopupMixin from "@mixins/PopupMixin.vue"
import CompanyMultipleSelect from "@components/company/CompanyMultipleSelect.vue"
import {Table,Select} from "ant-design-vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import _debounce from "lodash/debounce"
import {getEnumSelectOptions} from "@utils/formUtil"
import {lookupStatusDotMode} from "@utils/serviceUtil"
import StatusDot from "@components/common/StatusDot.vue"
import _isEqual from "lodash/isEqual"
export default {
	components : {
		"a-table" : Table, "a-select" : Select,
		CompanyMultipleSelect,
		StatusDot,
	} ,
	mixins : [LoginInfoMixin,PopupMixin] ,
	page() {
		return {
			title : this.$t('page.title')
		}
	} ,
	data() {
		return {
			loading : false,
			serviceDataList : [],
			pagination : {
				pageSize : 10 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : 'serviceUpdatedDatetime',
				order : 'descend',
			} ,
			filter : {
				companyIds : undefined,
				keyword : undefined,
				claimStatusMode : undefined,
				paymentRequest : undefined,
			}
		}
	} ,
	computed : {
		claimStatusModeOptions() {
			return getEnumSelectOptions(this,'claim_status_mode',['prepare','wait-decision','decision'])
		} ,
		paymentRequestOptions() {
			return getEnumSelectOptions(this,'claim_payment_request',['none','request','complete'])
		} ,
		claimColumns() {
			const columns = [
				{
					title : this.$t('claim.table.service_no') ,
					key : 'serviceNo' ,
					sorter : true,
					dataIndex : 'serviceNo' ,
					scopedSlots: { customRender: 'serviceNoRender' },
					class : 'col-service-no' ,
				} ,
				{
					title : this.$t('claim.table.drone') ,
					key : 'droneServiceDroneInstance' ,
					dataIndex : 'droneServiceDroneInstance' ,
					scopedSlots : { customRender : 'droneRender'} ,
					class : 'col-drone' ,
				} ,
				{
					title : this.$t('claim.table.company') ,
					key : 'serviceCompanyId' ,
					dataIndex : 'serviceCompanyId' ,
					scopedSlots : { customRender : 'companyRender'} ,
					class : 'col-company'
				} ,
				{
					title : this.$t('claim.table.service') ,
					key : 'service' ,
					children : [
						{
							title : this.$t('claim.table.service.open_date') ,
							key : 'serviceOpenDate' ,
							dataIndex : 'serviceOpenDate',
							sorter : true,
							scopedSlots : { customRender : 'dateRender'} ,
							class : 'col-date' ,
						} ,
						{
							title : this.$t('claim.table.service.status') ,
							key : 'serviceStatus' ,
							dataIndex : 'serviceStatus',
							scopedSlots : { customRender : 'serviceStatusRender'} ,
							class : 'col-status' ,
						} ,
						{
							title : this.$t('claim.table.service.payment_status') ,
							key : 'servicePaymentStatus' ,
							dataIndex : 'servicePaymentStatus',
							scopedSlots : { customRender : 'paymentStatusRender'} ,
							class : 'col-status' ,
						}
					]
				} ,
				{
					title : this.$t('claim.table.claim') ,
					key : 'claim' ,
					children : [
						{
							title : this.$t('claim.table.claim.status') ,
							key : 'serviceClaimStatus' ,
							dataIndex : 'serviceClaimStatus' ,
							scopedSlots : {customRender : 'claimStatusRender'} ,
							class : 'col-status' ,
						} ,
						{
							title : this.$t('claim.table.claim.requested_datetime') ,
							key : 'serviceClaimRequestedDatetime' ,
							sorter : true,
							dataIndex : 'serviceClaimRequestedDatetime' ,
							scopedSlots : {customRender : 'dateRender'} ,
							class : 'col-date' ,
						} ,
						{
							title : this.$t('claim.table.claim.decision_datetime') ,
							key : 'serviceClaimDecisionDatetime' ,
							sorter : true,
							dataIndex : 'serviceClaimDecisionDatetime' ,
							scopedSlots : {customRender : 'dateRender'} ,
							class : 'col-date' ,
						}
					]
				} ,
				{
					title : this.$t('claim.table.claim_payment') ,
					key : 'claim-payment' ,
					children : [
						{
							title : this.$t('claim.table.claim_payment.payment_request') ,
							key : 'claimPaymentRequest' ,
							scopedSlots : {customRender : 'claimPaymentStatusRender'} ,
							class : 'col-status' ,
						} ,
						{
							title : this.$t('claim.table.claim_payment.total_claim_fee') ,
							key : 'serviceClaimTotalClaimFee' ,
							dataIndex : 'serviceClaimTotalClaimFee' ,
							sorter : true,
							scopedSlots : {customRender : 'priceRender'} ,
							class : 'col-price' ,
						} ,
						{
							title : this.$t('claim.table.claim_payment.payment_requested_date') ,
							key : 'serviceClaimPaymentRequestedDate' ,
							dataIndex : 'serviceClaimPaymentRequestedDate' ,
							sorter : true,
							scopedSlots : {customRender : 'dateRender'},
							class : 'col-date' ,
						} ,
						{
							title : this.$t('claim.table.claim_payment.payment_completed_date') ,
							key : 'serviceClaimPaymentCompletedDate' ,
							dataIndex : 'serviceClaimPaymentCompletedDate' ,
							sorter : true,
							scopedSlots : {customRender : 'dateRender'},
							class : 'col-date' ,
						}
					]
				}
			];
			return columns
		} ,
	},
	watch: {
		filter: {
			handler() {
				this.debounceSearchChange()
			},
			deep: true,
		},
	},
	created() {
		if (this.$route.query.claim_mode) {
			if (this.claimStatusModeOptions.findIndex((status) => status.value==this.$route.query.claim_mode) >= 0)
				this.filter.claimStatusMode = this.$route.query.claim_mode
		}
		if (this.$route.query.payment_request) {
			if (this.paymentRequestOptions.findIndex((status) => status.value==this.$route.query.payment_request) >= 0)
				this.filter.paymentRequest = this.$route.query.payment_request
		}
		this.debounceSearchChange = _debounce(this.handleSearchChange,300);
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		lookupStatusDot(name,status) {
			return lookupStatusDotMode(name,status)
		} ,
		handleSearchChange() {
			this.pagination.current = 1;
			this.fetchData()
		} ,
		fetchData(toPage=null) {
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				pageSize : this.pagination.pageSize ,
				currentPage : toPage,
				sortField : this.sort.field ,
				sortOrder : this.sort.order ,
			}
			this.loading = true;
			const filter = { ...this.filter }
			axios.get("/api/services/search-claim-request-report",{params : {search,filter}}).then((response)=>{
				this.serviceDataList = response.data.data.services
				this.pagination.current = toPage
				this.pagination.total = response.data.data.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.columnKey;
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		} ,
		handleCompanyChange(companyId) {
			if (!_isEqual(companyId, this.filter.companyIds)) {
				this.filter.companyIds = companyId
			}
		} ,
		handleClaimStatusChange(claimStatus) {
			if (!_isEqual(claimStatus, this.filter.claimStatusMode)) {
				this.filter.claimStatusMode = claimStatus
			}
		} ,
		handlePaymentRequestChange(paymentRequest) {
			if (!_isEqual(paymentRequest, this.filter.paymentRequest)) {
				this.filter.paymentRequest = paymentRequest
			}
		}
	}
}
</script>

<style lang="less" scoped>
.claim-select {
	width : 150px;
}
.claim-table::v-deep {
	min-width : 1450px;
	th {
		text-align: center;
	}
	.col-company {
		width : 120px;
	}
	.col-service-no {
		width : 150px;
	}
	.col-drone {

	}
	.col-price {
		width : 100px;
	}
	.col-date {
		width : 125px;
	}
	.col-status {
		width : 125px;
	}

	.mobile & .ant-table ,
	.tablet & .ant-table {
		font-size : 0.9em;
		.col-company {
			width : 100px;
		}
		.col-service-no {
			width : 135px;
		}
		.col-price {
			width : 80px;
		}

	}

	.ant-table-tbody {
		.col-status , .col-date {
			text-align : center;
			font-size : 0.95em;
		}
		.col-price {
			text-align : right;
			font-size : 0.95em;
		}
	}
}

</style>
