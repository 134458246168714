import get from "lodash/get"
export function listAllFieldNames(formObj) {
	if (formObj && ("fieldsStore" in formObj)) {
		const rtn = [];
		for(const field in formObj.fieldsStore.fieldsMeta) {
			rtn.push(field);
		}
		return formObj.fieldsStore.getAllFieldsName();
	} else {
		return []
	}
}

export function updateFieldsValue(formObj,model) {
	const fields = listAllFieldNames(formObj);
	if (fields.length == 0)
		return;
	const values = {}
	fields.forEach((field) => {
		// check type
		const meta = formObj.fieldsStore.getFieldMeta(field);
		const defaultVal = meta.initialValue;
		if (get(meta,'originalProps.type',null) === 'checkbox' && get(meta,'valuePropName',null) === 'checked') {
			values[field] = (model && (field in model)) ? model[field] == 1 : defaultVal
		} else {
			values[field] = (model && (field in model)) ? model[field] : defaultVal
		}
	})
	formObj.setFieldsValue(values)
}

export function assignFieldErrors(formObj,fieldErrors) {
	const fields = listAllFieldNames(formObj);
	const result = {};
	fields.forEach((field) => {
		if (field in fieldErrors) {
			const currentVal = formObj.getFieldValue(field);
			result[field] = {
				value: currentVal,
				errors : fieldErrors[field].map((error)=>new Error(error))}
		}
	})
	formObj.setFields(result);
}
export function scrollTopContent(targetId=null,behavior='smooth') {
	if (targetId) {
		const ele = document.getElementById(targetId)
		if (ele)
			ele.scrollIntoView({behavior})
	} else {
		const ele = document.getElementById('tunjai_layout_content')
		if (ele)
			ele.scrollTo({top : 0,behavior})
	}
}

export function getEnumSelectOptions(component,enumName,enumList) {
	const options = []
	for(const enumValue of enumList) {
		options.push({
			label : component.$tenum(enumName,enumValue) ,
			value : enumValue
		})
	}
	return options
}
